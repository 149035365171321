import React from 'react';

import './rating-box.scss';

interface IProps {
  game: string;
  rating: string | number;
  ratingName: string;
  customClass?: string;
  modeCompact?: boolean;
  showName?: boolean;
}

function ratingToTierAG(rating) {
  if (rating === '5' || rating === 5) {
    return 'D';
  } else if (rating === '6' || rating === 6) {
    return 'C';
  } else if (rating === '7' || rating === 7) {
    return 'B';
  } else if (rating === '8' || rating === 8) {
    return 'A';
  } else if (rating === '9' || rating === 9) {
    return 'S';
  } else if (rating === '10' || rating === 10) {
    return 'SS';
  } else if (rating === '9.5' || rating === 9.5) {
    return 'S-plus';
  } else if (rating === '11' || rating === 11) {
    return 'SSS';
  } else if (rating === '4' || rating === 4) {
    return 'E';
  } else if (rating === '3' || rating === 3) {
    return 'F';
  }
  return '?';
}

function ratingToTierReverse(rating) {
  if (rating === '5' || rating === 5) {
    return 'C';
  } else if (rating === '6' || rating === 6) {
    return 'B';
  } else if (rating === '7' || rating === 7) {
    return 'A';
  } else if (rating === '8' || rating === 8) {
    return 'A-plus';
  } else if (rating === '9' || rating === 9) {
    return 'S';
  } else if (rating === '10' || rating === 10) {
    return 'S-plus';
  } else if (rating === '4' || rating === 4) {
    return 'D';
  }
  return '?';
}

function ratingToTierAFK(rating) {
  if (rating === '5' || rating === 5) {
    return 'D';
  } else if (rating === '6' || rating === 6) {
    return 'C';
  } else if (rating === '7' || rating === 7) {
    return 'B';
  } else if (rating === '8' || rating === 8) {
    return 'A';
  } else if (rating === '9' || rating === 9) {
    return 'S';
  } else if (rating === '10' || rating === 10) {
    return 'S-plus';
  } else if (rating === '4' || rating === 4) {
    return 'E';
  }
  return '?';
}

function ratingToTierGFL(rating) {
  if (rating === '6' || rating === 6) {
    return 'D';
  } else if (rating === '7' || rating === 7) {
    return 'C';
  } else if (rating === '7' || rating === 8) {
    return 'B';
  } else if (rating === '8' || rating === 9) {
    return 'A';
  } else if (rating === '10' || rating === 10) {
    return 'S';
  } else if (rating === '11' || rating === 11) {
    return 'S-plus';
  } else if (rating === '5' || rating === 5) {
    return 'E';
  }
  return '?';
}

function ratingToTierHSR(rating) {
  if (rating === '5' || rating === 5) {
    return 'T4';
  } else if (rating === '6' || rating === 6) {
    return 'T3';
  } else if (rating === '7' || rating === 7) {
    return 'T2';
  } else if (rating === '8' || rating === 8) {
    return 'T1.5';
  } else if (rating === '9' || rating === 9) {
    return 'T1';
  } else if (rating === '10' || rating === 10) {
    return 'T0.5';
  } else if (rating === '11' || rating === 11) {
    return 'T0';
  } else if (rating === '4' || rating === 4) {
    return 'T5';
  }
  return '?';
}

export const RatingBox: React.FC<IProps> = ({
  game,
  rating,
  ratingName,
  customClass,
  modeCompact,
  showName
}) => {
  const ratingConverted = ratingToTierAG(rating);
  const ratingConvertedRev = ratingToTierReverse(rating);
  const ratingConvertedHSR = ratingToTierHSR(rating);
  const ratingConvertedAFK = ratingToTierAFK(rating);
  const ratingConvertedGFL = ratingToTierGFL(rating);
  return (
    <div className={`rating-box-container ${customClass ? customClass : ''}`}>
      <span>
        {game === 'cs-old' && (
          <div className={`rating-box ${game} ${rating}`}>{rating}</div>
        )}
        {game === 'cs' && (
          <div
            className={`rating-box ${game} ${
              ratingConverted === '?' ? 'pending' : ratingConverted
            }`}
          >
            {ratingConverted === 'S-plus' ? 'S+' : ratingConverted}
          </div>
        )}
        {game === 'ag' && (
          <div className={`rating-box ${game} ${ratingConverted}`}>
            {ratingConverted}
          </div>
        )}
        {game === 'nikke' && (
          <div
            className={`rating-box ${game} ${
              ratingConverted === '?' ? 'pending' : ratingConverted
            }`}
          >
            {ratingConverted === 'S-plus' ? 'S+' : ratingConverted}
          </div>
        )}
        {game === 'gfl' && (
          <div
            className={`rating-box ${game} ${
              ratingConvertedGFL === '?' ? 'pending' : ratingConvertedGFL
            }`}
          >
            {ratingConvertedGFL === 'S-plus' ? 'S+' : ratingConvertedGFL}
          </div>
        )}
        {game === 'afk' && (
          <div
            className={`rating-box ${game} ${
              ratingConvertedAFK === '?' ? 'pending' : ratingConvertedAFK
            }`}
          >
            {showName && (
              <>
                <span className="rating-name">{ratingName}&nbsp;&#40;</span>
              </>
            )}
            {ratingConvertedAFK === 'S-plus' ? 'S+' : ratingConvertedAFK}
            {showName && <span className="rating-name">&#41;</span>}
          </div>
        )}
        {game === 'new-nikke' && (
          <div
            className={`rating-box ${game} ${
              rating === '?' ? 'pending' : rating
            }`}
          >
            {rating}
          </div>
        )}
        {game === 'reverse' && (
          <div
            className={`rating-box reverse ${game} ${
              ratingConvertedRev === '?' ? 'pending' : ratingConvertedRev
            }`}
          >
            {ratingConvertedRev === 'S-plus' ? (
              'S+'
            ) : (
              <>{ratingConvertedRev === 'A-plus' ? 'A+' : ratingConvertedRev}</>
            )}
          </div>
        )}
        {game === 'hsr' && (
          <div className={`rating-box ${game} rating-hsr-${rating}`}>
            {ratingConvertedHSR}
          </div>
        )}
      </span>
      {!modeCompact ? <p>{ratingName}</p> : ''}
    </div>
  );
};
